/**
 * Helpers
 */
import { fromBreakpoint, onVisibilityChange, toBreakpoint } from './helpers';

/**
 * Web standards
 */
import './cookieconsent';
import './softscroll';
import './lazyload';
import 'alpinejs/dist/cdn.min.js';
import { modal } from './modal';

/**
 * Scripts which don't need dynamic import
 */
let wpcf7forms = document.querySelector('.wpcf7-form');
if (wpcf7forms) {
	const jquery = import('jquery');
	global.jQuery = require('jquery');
	window.jQuery = require('jquery');
}

/**
 * Add scripts or styles based on loaded DOM
 */
addEventListener('DOMContentLoaded', () => {
	// TODO set import dynamic
	const splide = import('./splide');

	if (document.querySelectorAll('div[data-modal-target]').length > 0) {
		modal.init();
	}
});

/**
 * Dynamic import scripts
 */
const elements = [
	// '#header-video'
];

[].forEach.call(elements, (element) => {
	if (element !== '') {
		if (
			document.querySelector(element) !== null &&
			document.querySelector(element) !== undefined
		) {
			const lazyloadHandler = onVisibilityChange(
				document.querySelector(element),
				function () {
					// if (element === '#header-video') {
					//   const headerVideo = import('./header.video')
					// }
				}
			);

			if (window.addEventListener) {
				addEventListener('DOMContentLoaded', lazyloadHandler, false);
				addEventListener('load', lazyloadHandler, false);
				addEventListener('scroll', lazyloadHandler, false);
				addEventListener('resize', lazyloadHandler, false);
			} else if (window.attachEvent) {
				attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
				attachEvent('onload', lazyloadHandler);
				attachEvent('onscroll', lazyloadHandler);
				attachEvent('onresize', lazyloadHandler);
			}
		}
	}
});
